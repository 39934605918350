/* You can add global styles to this file, and also import other style files */

/* @import url("https://fonts.googleapis.com/css2?family=Epilogue&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
    background: linear-gradient(293.27deg, rgb(243, 226, 226) 13.28%, rgb(230, 234, 230) 55.92%, rgb(218, 240, 241) 95.97%);
} */

body {
    background-color: #F2E4CF;
    min-height: 100vh;
}

